const routes = {
  home: '/r/[hotelId]',
  roomTypes: '/r/[hotelId]/room-types',
  calendar: '/r/[hotelId]/calendar',
  plans: '/r/[hotelId]/plans',
  reservation: '/r/[hotelId]/reservation',
  rooms: '/r/[hotelId]/rooms',
  mypage: '/r/[hotelId]/mypage',
  login: '/r/[hotelId]/login',
  register: '/r/[hotelId]/register',
  mailSent: '/r/[hotelId]/mail-sent',
  jammyLp: 'https://service.jammy.jp/',
}

export { routes }
