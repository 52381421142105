import { Center, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { routes } from 'src/util/routes'

function Top() {
  useEffect(() => {
    window.location.href = routes.jammyLp
  }, [])

  return (
    <Center h="100vh">
      <meta name="robots" content="noindex,nofollow" />
      <Text fontSize="36px">Not found</Text>
    </Center>
  )
}

const DynamicTopPage = dynamic(
  {
    loader: async () => Top,
  },
  { ssr: false }
)

export default DynamicTopPage
